/* global SHEIN_BACKUP_ONETRUST */
import { daEventCenter } from 'public/src/services/eventCenter'
import schttp from 'public/src/services/schttp'
const { CUSTOM_PRIVACY_SUPPORT = [], SiteUID } = gbCommonInfo
// 判断当前站点是否需要使用自定义的弹窗 SDK
const isSupportCustomPrivacySDK = () => {
  if (CUSTOM_PRIVACY_SUPPORT.includes(SiteUID)) {
    return true
  }
  return false
}

const EVENT_ID_MAP = {
  expose_privacy_popup: '2-42-1',
  click_privacy_popup: '2-42-2',
  click_privacy_setting: '2-42-3',
  expose_privacy_popup_stay: '2-42-4',
  click_privacy_save_setting: '2-42-5',
  click_privacy_footer_entrance: '2-42-6',
  click_privacy_footer_save: '2-42-7',
  expose_privacy_anomaly_causes: '2-42-8'
}

daEventCenter.addSubscriber({ modulecode: '2-42' })

// 判断当前页面是否需要展示隐私弹窗
const isCurrentPageShouldShowCookieBanner = () => {
  const { page_name } = window.getSaPageInfo || window.SaPageInfo || {}
  const supportPage = ['page_home', 'page_goods_detail', 'page_category', 'page_daily_new', 'page_real_class', 'page_select_class', 'page_search', 'page_goods_group']

  if (supportPage.includes(page_name)) {
    return true
  }
  return false
}

const InitBackUpOnetrustPop = () => {
  import(/* webpackChunkName: "backup-onetrust" */'../onetrust/backup').then(() => {
    SHEIN_BACKUP_ONETRUST.init(() => {
      SHEIN_BACKUP_ONETRUST.show()
    })
    window.privacyCookieSdk.openPreference = SHEIN_BACKUP_ONETRUST.settings
  })
}

const getMemberLatestCookie = async () =>{
  const result = await schttp({
    method: 'post',
    url: '/api/common/get/member/latestCookies'
  })

  if(result?.code == '0' && result?.info){
    return result.info
  }else{
    return null
  }
}

export async function initPrivacyPopDisplay() {
  try {
    if (!isSupportCustomPrivacySDK()) {
      return
    }

    // backup 降级弹窗展示
    if (window.privacyCookieSdkLoadedError) InitBackUpOnetrustPop()
    window.addEventListener('privacyCookieSdkLoadedError', () => InitBackUpOnetrustPop())

    if(typeof window.privacyCookieSdk === 'undefined') return

    const userAgreeInfo = await getMemberLatestCookie()

    // 请求回来了 sdk重新启动拦截
    window.privacyCookieSdk.setUserAgreeInfo(userAgreeInfo)
    // 重新加载bat.js，但已经不是之前bat.js
    
    window.privacyCookieSdk.setListeners({
      onSDKError: (e) => {
        // 弹出兜底弹窗
        InitBackUpOnetrustPop()
        console.error('=====弹出兜底弹窗', e)
      },
      onSubmit: (reqData) => {
        const {
          scene,
          rule_id,
          template_id,
          template_version,
          domain,
          operation,
          agree,
          disagree,
          expire,
          force,
        } = reqData
        schttp({
          method: 'post',
          url: '/api/common/save/privacy/cookies',
          data: {
            scene,
            source: 3,
            rule_id,
            template_id,
            template_version,
            domain,
            operation,
            agree,
            disagree,
            expire,
            force,
            termainl: 'pc'
          }
        })
      },
      onTrack: (reqData) => {
        const { event, attrs } = reqData
        const id = EVENT_ID_MAP[event]
        if (id) {
          daEventCenter.triggerNotice({
            daId: id,
            extraData: attrs
          })
        }
      }
    })
    window.privacyCookieSdk.createPreference()
    if(isCurrentPageShouldShowCookieBanner()){
      window.privacyCookieSdk.showAgreementIfNeed()
    }
  } catch (error) {
    console.error('======弹出兜底弹窗=====', error)
    // 弹出兜底弹窗
    InitBackUpOnetrustPop()
  }
}

